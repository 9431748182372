import React from "react";
import "./Footer.css"
import { SocialIcon } from 'react-social-icons';

const Footer =()=>{


    return(
        <div className="MainFooter">
            <div className="LeftInformation">
            <a href="mailto:mppablomp@hotmail.com" >Contact</a>
            
            </div>
            <div className="RightInformation"> 
                <p>Social Media</p>
            <SocialIcon fgColor="#ffffff" url="https://twitter.com/SYSTEMCRASH92" />
            </div>   


        </div>
    )




}


export default Footer
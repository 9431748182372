
import './App.css';
import Nav from "./Components/Nav/Nav";
import Homevideo from "./Components/HomeVideo/Homevideo.jsx"
import Overview from "./Components/Overview/Overview.jsx"
import UncontrolledExample from './Components/Media/Media';
import Faq from './Components/Faq/Faq';
import Footer from './Components/Footer/Footer';
import Avatars from './Components/Avatars/Avatars';


function App() {
  return (
    <div className="App">
        <Homevideo/>
        <Footer/>

    </div>
  );
}

export default App;

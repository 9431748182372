import React, { useEffect } from "react";
import "./Homevideo.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Homevideo = () => {
  useEffect(() => {
    Aos.init({ duration: 4500 });
  }, []);

  return (
    <div id="Home" data-aos="fade-left" className="ContainerHomeVideo">
      <div className="containerchill">
        <img src="./images/LogoSC92.png" alt="" />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/KqsEHg3T2sA"
          title="YouTube video player"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <iframe
        src="https://store.steampowered.com/widget/2411880/"
        
        width="646"
        height="190"
      ></iframe>
    </div>
  );
};

export default Homevideo;
